import React, { Component } from "react";

import Upload from "../../../../components/FileUpload";
import API from "../../../../lib/api";
import { getMessage } from "../../../../lib/translator";
import { isExtensionEnabled } from "../../../../lib/auth";
import { Link } from "react-router-dom";
import csvIcon from "./csv-icon.svg";
import uploadIcon from "./upload-products.svg";
import { getSession } from "../../../../lib/auth";
import { isSellerSection } from "..";
import "./style.css";

function downloadCsv(data, uploadType) {
  let csv = "";
  csv += data.join(",");
  csv += "\n";
  var hiddenElement = document.createElement("a");
  hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
  hiddenElement.target = "_blank";
  hiddenElement.download =
    uploadType === "inventory" ? "inventory.csv" : "product.csv";
  hiddenElement.click();
}

export default class ProductUpload extends Component {
  constructor(props) {
    super(props);
    this.getSampleCsv = this.getSampleCsv.bind(this);
  }

  getSampleCsv(e) {
    e.preventDefault();
    const { bulkUploadType } = this.props;
    if (bulkUploadType === "catalogue") {
      const api = new API({ url: "/catalogue-service/product-upload" });
      api
        .get()
        .then(
          (response) => {
            downloadCsv(response.data.fileUpload || [], bulkUploadType);
          },
          (error) => {
            this.setState({ uploadError: error.message });
          }
        )
        .catch((error) => {
          console.error(error);
        });
    } else if (bulkUploadType === "inventory") {
      const country = new API({
        url: `/account-service/country?isoCode=${
          getSession().organization.countryCode
        }`,
      });
      country
        .get()
        .then((response) => {
          const taxHeaders = response.data.country.tax.split(",");
          const inventoryCsvHeaders = [
            "Store ID",
            "Item ID",
            "Price",
            "Discount",
            ...taxHeaders,
            "Stock",
          ].concat(
            isExtensionEnabled("InStoreProcessing")
              ? ["Rack", "Aisle", "Shelf"]
              : []
          );
          downloadCsv(inventoryCsvHeaders, bulkUploadType);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  render() {
    let { hideAddButton, disableUpload, bulkUploadType } = this.props;
    return (
      <div className="product-upload">
        {!hideAddButton ? (
          <div className="text-center">
            <Link
              to={
                isSellerSection()
                  ? `/catalogue/products/search-and-add`
                  : `/catalogue/products/add`
              }
              className="button primary add-product-link"
            >
              + {getMessage("product.add.text")}
            </Link>
          </div>
        ) : null}
        <div className="bordered-box">
          <div className="text-center">
            <img src={uploadIcon} width="140" alt="" />
          </div>
          {!hideAddButton ? (
            <span className="border-text">
              {getMessage("productUpload.orText")}
            </span>
          ) : null}
          <Upload
            name="bulkProductUpload"
            accept=".csv, text/csv, text/comma-separated-values, application/csv, application/excel, application/vnd.ms-excel, application/vnd.msexcel"
            placeholder={getMessage("productUpload.uploadFileText")}
            uploadButtonText="Upload"
            cancelText="Cancel"
            uploadUrl={
              bulkUploadType === "inventory"
                ? "/inventory-service/inventory-file"
                : "/catalogue-service/product-upload"
            }
            validationStrings={{
              invalidFileType: getMessage("input.invalidFileType"),
            }}
            strings={{
              defaultMessage: getMessage("fileUpload.importCsv.heading"),
              progressMessage: getMessage("fileUpload.importedCsv.heading"),
              completionMessage: getMessage("fileUpload.uploadedCsv.heading"),
            }}
            storeIds={this.props?.storeIds}
            icon={csvIcon}
            disableUpload={disableUpload}
            uploadKey={bulkUploadType === "inventory" ? "inventoryFile" : ""}
          />
        </div>
        <div className="text-center">
          <button
            className={`download-link${disableUpload ? " disabled" : ""}`}
            onClick={disableUpload ? undefined : this.getSampleCsv}
          >
            {getMessage("productUpload.csvDownloadText")}
          </button>
        </div>
      </div>
    );
  }
}
